@use '../style/colors.scss';

#gallery {
    padding: 80px 20px 140px;

    @media  screen and (max-width: 500px) {
        padding: 50px 5px 80px;
    }

    background-color: colors.$light;

    h2 {
        margin: 0px 20px 80px 0px;
    }

    .carousel-item {
        padding: 10px;
        
        img {
            width: 100%;
            height: auto;
            border: 6px solid #fff;
            box-shadow: 3px 3px 5px #00000070;
        }
    }

    }
