/* $light: rgba(218, 237, 251, 1);
$light-t: rgba(218, 237, 251, 0.9);
$error: rgba(212, 66, 53, 1);
$dark: rgba(89, 98, 85, 1);
$secondary: rgb(10, 84, 255);
$primary: rgb(15, 26, 67);
$secondary-t: rgba(10, 84, 255, 0.8);
$primary-t: rgba(15, 26, 67, 0.93); */

$primary: rgb(69, 104, 123);

$secondary: rgb(157, 177, 186);
$tertiary: rgba(157, 177, 186, 0.3);
$light: rgb(229, 224, 228);
$dark: rgba(28, 28, 35, 1);
$error: rgb(220, 40, 40);
$attention: rgb(40, 40, 220);

//$primary-t: hsla(355, 85%, 25%, 0.6);
//$secondary-t: #0b4f6ca0;
$light-t: rgba(229, 224, 228, 0.5);
$dark-t: rgba(28, 28, 35, 0.9);
