@use '../style/colors.scss';

.language-select-container {
    display: flex;
    align-items: center;

}

.menu-item{
    display: flex;
    align-items: center;

    .flag {
        width: 24px;
        height: 18px;
    
        margin-right: 5px;
    }

    p {
        font-size: 16px;
    }
}

