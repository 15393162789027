@use '../style/colors.scss';

#about {
    display:flex;
    min-height: 100vh;
    
    margin: 60px 20px 40px;
    background: linear-gradient(colors.$secondary 0%, colors.$secondary 90%);
    box-shadow: 5px 5px 10px #00000088;
    
    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        margin: 60px 10px 40px;
    }

    .img-container {
        width: 40%;

        background-position: center;
        background-size: cover;

        @media screen and (max-width: 900px) {
            width: 100%;
            height: 85vw;
        }
    }
    
    .text-container {
        width: 60%;
                
        padding: 70px 30px 40px;
        background-color: colors.$primary;
        
        color: colors.$light;

        @media screen and (max-width: 900px) {
            width: 100%;

            padding: 40px 20px;
        }

        p {
            text-align: justify;

            @media screen and (max-width: 400px) {
                font-size: 1em;
            }
    
        }

        p:not(:last-child) {
            margin-bottom: 20px;
        }

    }
}