@use '../style/colors.scss';

#footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;

    padding: 0px 40px;
    background-color: colors.$tertiary;
    box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 600px) {
        padding: 0px 15px;
    }

}