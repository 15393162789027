@use '../style/colors.scss';


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


#impressum {

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    padding: 80px 0px 40px;
    background-color: colors.$light;

    color: colors.$primary;

    animation: fadeIn 150ms ease-out;

    div.text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;

        margin: 5px;
        padding: 50px 25px;
        border: 1px solid colors.$primary;
        border-radius: 3px;

        text-align: center;

        h4 {
            margin-bottom: 40px;

            font-size: 1.6em;
        }

        p {
            margin-bottom: 25px;
        }
    }
}
