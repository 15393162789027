@use '../style/colors.scss';
$breakpoint: 1140px;

nav {
    z-index: 100;

    @media screen and (min-width: calc($breakpoint + 1px)) {
        position: fixed;
        top: 0px;
    }
    
    .nav-bar {
        z-index: inherit;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        max-width: 1400px;
        height: 60px;
        
        padding: 0px 15px;

        background-color: colors.$light;

        @media screen and (max-width: $breakpoint) {
            position: fixed;
            left: 0px;
            bottom: -100%;

            flex-direction: column;
            justify-content: space-around;
            align-items: flex-end;
            width: 100vw;
            height: 350px;

            padding: 20px 20px 20px 20px;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7);

            transition: bottom 400ms ease-in-out;

            &.nav-bar-on {
                bottom: 0px;
            }
        }

        div.nav-header {
            display: flex;
            align-items: center;

            @media screen and (max-width: $breakpoint) {
                display: none;
            }

            cursor: pointer;

            img {
                width: 45px;
                height: auto;
            }

            h2 {
                margin-left: 10px;

                font-size: 1.8em;
                font-weight: 700;
                color: colors.$primary;
                text-transform: uppercase;
                text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
            }
        }
    }

    #toggle-btn {
        position: fixed;
        right: 35px;
        top: 40px;
        z-index: inherit;

        padding: 10px 5px;

        svg {
            filter: drop-shadow(3px 3px 1px #ffffffee);
        }

        @media screen and (max-width: 470px) {
            right: 15px;
            top: 20px;
            }    
        
        @media screen and (min-width: calc($breakpoint + 1px)) {
                display: none;
        }
    }
}