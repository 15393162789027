@use '../style/colors.scss';

@keyframes slideIn {
    0% {
        top: 60vh;
        opacity: 0;
    }

    100% {
        top: 50vh;
    }
}

#info-board {
    position: fixed;
    z-index: 100;
    left: 50vw;
    top: 50vh;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    max-width: 700px;
    
    padding: 50px 80px;
    background-color: colors.$primary;
    
    @media screen and (max-width: 700px) {
        padding: 50px 40px;
    }
    
    @media screen and (max-width: 470px) {
        padding: 30px 10px;
    }

    border-radius: 3px;
    box-shadow: 7px 7px 20px 5px colors.$dark-t;

    text-align: center;
    color: colors.$light;
    
    transform: translate(-50%, -50%);
    animation: slideIn 500ms ease-out;

    h3 {
        margin-bottom: 30px;

        font-size: 2.6em;
    }

    p {
        margin-bottom: 30px;

        font-size: 1.4em;
        @media screen and (max-width: 470px) {
            font-size: 1.2em;
        }
    
    }    
}
